var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"pr-10 pl-10 mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{staticClass:"text-button align-self-center text-center",attrs:{"cols":"11","sm":"11","md":"11"}},[_c('div',{staticClass:"align-self-center"},[_c('v-text-field',{attrs:{"dense":"","single-line":"","outlined":"","clearable":"","placeholder":"ジョブ名を入力"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch()}},model:{value:(_vm.keySearch),callback:function ($$v) {_vm.keySearch=$$v},expression:"keySearch"}})],1)]),_c('v-col',{staticClass:"text-button",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text-h4",attrs:{"block":"","color":"accent"},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v(" "+_vm._s(_vm.$t('messages.search'))+" ")])],1)],1),_c('v-row',{staticClass:"mb-5"},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mb-5 pt-0 pl-10 pr-10 white--text",attrs:{"dense":"","color":"forth"}},[_c('span',{staticClass:"font-weight-bold text-h5"},[_vm._v(" "+_vm._s(_vm.$t('messages.Create')))])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.factoryOrders,"options":_vm.options,"items-per-page":10,"server-items-length":_vm.totalItems,"stle":"","loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': _vm.$t('table.messages.items_per_page'),
      },"no-data-text":_vm.$t('table.messages.no_data')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.messages.page'))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"item",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.$router.push({
            path: '/rice-factory-jobs/detail/' + props.item.jobId,
          })}}},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getIndex(props.index))+" ")]),_c('td',{staticClass:"text-center",style:({ color: props.item.color })},[_vm._v(" "+_vm._s(props.item.jobName?props.item.jobName: '--')+" ")]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(props.item.jobStatus)+" ")])]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(props.item.jobCreated))])])])]}}])})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }